import request from "./request";

export function getSiteListByPlatForm(params){
    return request({
        url: 'order/getSiteListByPlatForm',
        method: 'post',
        data: params
    });
}

export function getProduct(params){
    return request({
        url: 'order/getProduct',
        method: 'post',
        data: params
    });
}

export function getProductSku(params){
    return request({
        url: 'order/getProductSku',
        method: 'post',
        data: params
    });
}

export function addOrder(params){
    return request({
        url: 'order/addOrder',
        method: 'post',
        data: params
    });
}

export function getCurrency(params){
    return request({
        url: 'order/getCurrency',
        method: 'post',
        data: params
    });
}

export function getOrderList(params){
    return request({
        url: 'order/getOrderList',
        method: 'post',
        data: params
    });
}


export function getOrder(params){
    return request({
        url: 'order/getOrder',
        method: 'post',
        data: params
    });
}

export function updateOrder(params){
    return request({
        url: 'order/updateOrder',
        method: 'post',
        data: params
    });
}

export function updateOrderStatus(params){
    return request({
        url: 'order/updateOrderStatus',
        method: 'post',
        data: params
    });
}

export function updateOrderNote(params){
    return request({
        url: 'order/updateOrderNote',
        method: 'post',
        data: params
    });
}

export function getDeclaration(params){
    return request({
        url: 'order/getDeclaration',
        method: 'post',
        data: params
    });
}

export function getExpressesChannel(params){
    return request({
        url: 'order/getExpressesChannel',
        method: 'post',
        data: params
    });
}


export function createExpresses(params){
    return request({
        url: 'order/createExpresses',
        method: 'post',
        data: params
    });
}

export function deleteOrderExpresses(params){
    return request({
        url: 'order/deleteOrderExpresses',
        method: 'post',
        data: params
    });
}

export function expressesLabel(params){
    return request({
        url: 'order/expressesLabel',
        method: 'post',
        data: params
    });
}

export function getWareHouse(params){
    return request({
        url: 'order/getWarehouse',
        method: 'post',
        data: params
    });
}

export function getStockList(params){
    return request({
        url: 'order/getStockList',
        method: 'post',
        data: params
    });
}

export function sendOrder(params){
    return request({
        url: 'order/sendOrder',
        method: 'post',
        data: params
    });
}

export function refundOrder(params){
    return request({
        url: 'order/refundOrder',
        method: 'post',
        data: params
    });
}

export function refundReceiveOrder(params){
    return request({
        url: 'order/refundReceiveOrder',
        method: 'post',
        data: params
    });
}

export function getExportData(params){
    return request({
        url: 'order/getExportData',
        method: 'post',
        data: params
    });
}

export function syncPlatOrder(params){
    return request({
        url: 'order/syncPlatOrder',
        method: 'post',
        data: params
    });
}

