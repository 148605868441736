<template>
    <v-app style="background: transparent" :class="{}">
        <template v-if="route_children">
            <router-view></router-view>
        </template>
        <template v-else>
            <!-- begin:: Content Head -->
            <KTSubheader
                    v-if="nav.subheaderDisplay"
                    v-bind:breadcrumbs="nav.breadcrumbs"
                    v-bind:title="nav.pageTitle"
            />
            <!-- end:: Content Head -->

            <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container">
                    <div class="card condition" role="alert">
                        <div class="box-card">
                            <p>订单状态：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.status===''?'active':'']"
                                       @click="search_form.status=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in status_list"
                                    v-bind:key="item.value">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.status===item.value?'active':'']"
                                       @click="search_form.status=item.value"
                                    >{{item.text}}</a>
                                </li>

                            </ul>
                        </div>
                        <div class="box-card">
                            <p>平台渠道：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.plat_form==''?'active':'']"
                                       @click="search_form.plat_form=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in platform_list"
                                    v-bind:key="item.value">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.plat_form==item.value?'active':'']"
                                       @click="search_form.plat_form=item.value"
                                    >{{item.text}}</a>
                                </li>

                            </ul>
                        </div>
                        <div class="box-card">
                            <p>站点店铺：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.site_id==''?'active':'']"
                                       @click="search_form.site_id=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in site_list"
                                    v-bind:key="item.id">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.site_id==item.id?'active':'']"
                                       @click="search_form.site_id=item.id"
                                    >{{item.site_name}}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="box-card">
                            <p>订单类型：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.type==''?'active':'']"
                                       @click="search_form.type=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in type_list"
                                    v-bind:key="item.id">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.type==item.value?'active':'']"
                                       @click="search_form.type=item.value"
                                    >{{item.text}}</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <!--begin::Card-->
                    <div class="card card-custom">
                        <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                                <h3 class="card-label">
                                    订单列表
                                    <span class="d-block text-muted pt-2 font-size-sm"></span>
                                </h3>
                            </div>
                            <div class="card-toolbar">

                                <!--begin::Button-->
                                <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addOrder()">
                                    新增手工单
                                </a>
                                <!--end::Button-->
                            </div>
                        </div>
                        <div class="card-body">
                            <!--begin: Search Form-->

                            <!--begin::Search Form-->
                            <div class="mb-7">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 col-xl-12">
                                        <div class="row align-items-center">
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">开始时间:</label>
                                                    <KTDatePicker
                                                            v-model="search_form.begin_time"
                                                    ></KTDatePicker>
                                                </div>
                                            </div>
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">结束时间:</label>
                                                    <KTDatePicker
                                                            v-model="search_form.end_time"
                                                    ></KTDatePicker>
                                                </div>
                                            </div>

                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">产品名称:</label>
                                                    <input type="text" class="form-control" placeholder="产品标题" v-model="search_form.product_title">
                                                </div>
                                            </div>

                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">订单单号:</label>
                                                    <input type="text" class="form-control" placeholder="订单单号" v-model="search_form.order_number">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-5">
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">下单账号:</label>
                                                    <input type="text" class="form-control" placeholder="下单账号" v-model="search_form.user_account">
                                                </div>
                                            </div>
                                            <div class="col-md-3 my-2 my-md-0">
                                                <a href="javascript:void(0);" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Search Form-->
                            <!--end: Search Form-->


                            <KTDatatable
                                    v-bind:list="list"
                                    v-bind:column="column"
                                    v-bind:showSelect="false"
                                    v-bind:showPagination="true"
                                    v-bind:pageSize="page.limit"
                                    v-bind:pageCount="page.pageCount"
                                    v-bind:total="page.total"
                                    @operateHandler="operateHandler"
                                    @pagechangeHandler="pagechangeHandler"
                            ></KTDatatable>

                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Container-->
            </div>

            <v-dialog v-model="dialog_show" persistent :max-width="dialog_width">
                <component
                        :key="dialog_id"
                        :title="dialog_title"
                        :visible="dialog_show"
                        :is="dialog_view"
                        :dialog_data="dialog_data"
                        @opeareResultHandler="opeareResultHandler"></component>
            </v-dialog>
        </template>
    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import KTDatePicker from "@/view/content/date/DatePicker.vue";
    import KTUtil from "@/assets/js/components/util";

    import refundReceive from '@/view/pages/refund/RefundReceive';

    import {getSiteListByPlatForm, getOrderList, updateOrderStatus, deleteOrderExpresses} from '@/api/order';
    import Swal from "sweetalert2";

    export default {
        name: "order_view",
        components: {
            KTSubheader,
            KTDatatable,
            KTDatePicker
        },
        created: function(){
            var _this = this;

            //判断路由
            if('/' + this.$route.name == this.$route.path){
                _this.searchList();
            } else {
                _this.route_children = true;
            }
            _this.getSiteList();
        },
        data() {
            return {
                route_children: false,
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/refund', 'title':'订单退款'}],
                    pageTitle: '订单'
                },
                status_list: [
                    {'value': 6, 'text': '退款中'},
                    {'value': 7, 'text': '退款完成'}
                ],
                type_list: [
                    {'value': 1, 'text': '网站订单'},
                    {'value': 2, 'text': '手工订单'},
                ],
                platform_list: KTUtil.getPlatForm(),
                site_list: [],
                list: [],
                column: [{
                    field: 'order_number',
                    title: '订单编号',
                    width: 150
                },{
                    field: 'user_account',
                    title: '客户账号',
                    width: 150
                },{
                    field: 'channel',
                    title: '支付方式',
                },{
                    field: 'pay_account',
                    title: '支付账号',
                },{
                    field: 'amount',
                    title: '订单金额',
                    formatter: function(row) {
                        return row.amount + ' ' + row.currency;
                    }
                },{
                    field: 'refund_amount',
                    title: '退款金额',
                    formatter: function(row) {
                        return row.refund_amount + ' ' + row.currency;
                    }
                },{
                    field: 'status',
                    title: '状态',
                    formatter: function(row) {
                        return KTUtil.getOrderStatus(row.status);
                    }
                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 150,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'operate',
                    title: '操作',
                    width: 100,
                    formatter: function(row){
                        let html = '';

                        if(row.status == 6) {
                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="退货收货">'+
                                '<i class="la la-plane-arrival icon-lg"></i>'+
                                '</a>';
                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="退款完成">'+
                                '<i class="la la-calendar-check-o icon-lg"></i>'+
                                '</a>';
                        }
                        return html;

                    }
                }],
                search_form: {
                    product_title: '',
                    plat_form: '',
                    site_id: '',
                    status: '',
                    order_number: '',
                    user_account: '',
                    begin_time: '',
                    end_time: '',
                    type: '',
                    refund: '1'
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null,
                dialog_width: '850px'
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if('/' + this.$route.name == this.$route.path){
                        this.route_children = false;
                    } else {
                        this.route_children = true;
                    }
                    this.searchList();
                },
                deep: true
            },
            'search_form.status':function (val){
                this.searchList();
            },
            'search_form.plat_form':function (val){
                this.getSiteList();
                this.searchList();

            },
            'search_form.site_id':function (val){
                this.searchList();
            },
            'search_form.type':function (val){
                this.searchList();
            }
        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getOrderList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;

              if(clazz.indexOf('la-plane-arrival') > -1){

                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '订单退货收货';
                    this.dialog_width = '1200px';
                    this.dialog_view = refundReceive;
                    this.dialog_data = {
                        form_data: {
                            'order': row
                        }
                    };
                } else if(clazz.indexOf('la-calendar-check-o') > -1){
                  KTUtil.confirm('操作提示', '是否确认退款完成？', function(){
                      updateOrderStatus({id: row.id, status: 7}).then(function (res) {
                          if (res) {
                              KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                              _this.searchList();
                          }
                      });
                  });
              }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addOrder() {
                this.$router.push({
                    path: '/order/addorder'
                });
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            },
            getSiteList() {
                var _this = this;
                getSiteListByPlatForm({'site_platform': _this.search_form.plat_form}).then(function(res) {
                    if(res && res.response){
                        _this.site_list = res.response;
                    }
                });
            },
            getOrderStatus(status) {
                return KTUtil.getOrderStatus(status);
            }
        }
    }
</script>
